<template>
  <div>
    <!-- input Recherche -->
    <!-- Add this new element to display the total delivery price -->
    <b-row
      v-if="user.role === 'admin' && isFiltered"
      class="mt-2 mb-2"
    >
      <b-col>
        <h4 class="total-price-shadow">
          Totale Prix de Livraison: {{ totalDeliveryPrice }} TND</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Numéro"
          label-for="Numéro"
        >
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="num"
              placeholder="Chercher"
              type="text"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Livreur"
          label-for="Livreur"
        >
          <v-select
            v-model="deliveryMan"
            :clearable="false"
            placeholder="Livreur"
            label="username"
            :options="deliveryMans"
            @input="getPayments(1)"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="user.role==='admin'">
        <b-form-group
          label="depot"
          label-for="depot"
        >
          <v-select
            v-model="repository"
            :clearable="false"
            placeholder="depot"
            label="name"
            :options="repositories"
            @input="getPayments(1)"
          />
        </b-form-group>
      </b-col>
      <b-form-group class="mt-2">
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker"
          placeholder="Date a Date"
          @on-change="getPayments"
        />
      </b-form-group>
      <b-form-group>
        <b-button
          variant="danger"
          class="ml-1 mt-2"
          @click="resetFilters"
        >
          <span class="ml-1">Reset</span>
        </b-button>
      </b-form-group>
      <!-- <b-form-group
        v-if="user.role==='admin' || user.role==='sales' "
        class="mt-2 ml-1"
      >
        <b-button
          v-if="!exportedPayments"
          title="Export Excel"
          :class="{ 'disabled-cursor': isLoading }"
          variant="success"
          :disabled="isLoading"
          @click="exportPayments"
        > Excel
          <b-icon
            icon="download"
            aria-hidden="true"
          />
        </b-button>
        <b-button
          v-if="exportedPayments"
          title="download excel"
          :class="{ 'disabled-cursor': isLoading }"
          :disabled="isLoading"
        >
          <download-excel
            :data="exportedPayments"
            :fields="exportSalesToExcelFields"
            worksheet="Sales"
            name="Sales"
          >
            <b-icon
              icon="cloud-download"
              aria-hidden="true"
            />
          </download-excel>
        </b-button>
      </b-form-group> -->

      <b-form-group>
        <b-button
          :to="{ name: 'payment-add' }"
          variant="gradient-primary"
          class="ml-1 mt-2"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter</span>
        </b-button>
      </b-form-group>
    </b-row>
    <div
      v-if="isLoading === true"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="tableColumns"
      :rows="payments"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'id'"
          class="text-nowrap"
        >
          <qrcode-vue
            style="display:inline"
            :value="props.row.id.toString()"
            :size="size"
            level="H"
          />
          <p class="ml-2">
            {{ props.row.id }}
          </p>

        </div>
        <!-- Column: Name -->
        <div
          v-else-if="props.column.field === 'delivery_man'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.delivery_man.username }}</span>
        </div>

        <!-- Column: Action -->
        <div v-else-if="props.column.field === 'action'">
          <p>
            <span>
              <router-link
                class="text-info"
                :to="{ name: 'payment-show',
                       params: { id: props.row.id} }"
              >  <feather-icon
                :id="`invoice-row-${props.row.id}-show-icon`"
                icon="EyeIcon"
                class="cursor-pointer mr-1"
                size="16"
              />
              </router-link>

              <b-tooltip
                title="Détail"
                class="cursor-pointer"
                :target="`invoice-row-${props.row.id}-show-icon`"
              />
            </span>
            <span v-if="user.role==='admin'">
              <feather-icon
                v-if="!props.row.is_validated"
                :id="`validate-payment-${props.row.id}`"
                icon="CheckIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="validatePayment(props.row.id)"
              />

              <b-tooltip
                title="Valider"
                class="cursor-pointer"
                :target="`validate-payment-${props.row.id}`"
              />
            </span>
          </p>
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> de {{ count }} entrées
            </span>
          </div>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="pageNext(currentPage)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BRow, BCol,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QrcodeVue from 'qrcode.vue'
import vSelect from 'vue-select'
import storeAuth from '@/store/store'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    BRow,
    BCol,
    QrcodeVue,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      exportedPayments: null,
      totalDeliveryPrice: 0,
      isFiltered: false,
      size: 80,
      isLoading: false,
      pageLength: 10,
      count: 0,
      dir: false,
      currentPage: 1,
      rangePicker: ['', ''],
      columns: [
        { label: 'Reference', field: 'id' },
        { label: 'Livreur', field: 'delivery_man.username' },
        { label: 'Depot', field: 'repository' },
        { label: 'N° Colis Livrés', field: 'delivred_orders_count' },
        { label: 'N° Colis en retour', field: 'return_orders_count' },
        { label: 'Total livraison', field: 'total_delivery_price', formatFn: value => `${value} TND` },
        { label: 'Total Prix TTC', field: 'delivred_price', formatFn: value => `${value} TND` },
        { label: 'Date | Heure', field: 'created_at', formatFn: this.formatFn },
        { label: 'Action', field: 'action' },
      ],
      exportSalesToExcelFields: {
        id: 'id',
        depot: 'depot',
        livreur: 'livreur',
        nombre_colis_livre: 'nombre_colis_livre',
        nombre_colis_retour: 'nombre_colis_retour',
        total_prix_ttc: 'total_prix_ttc',
        total_frais_livraison: 'total_frais_livraison',
        created_at: 'created_at',
      },
      payments: [],
      user: {},
      searchTerm: '',
      deliveryMan: '',
      deliveryMans: [],
      num: '',
      repositories: [],
      repository: '',
    }
  },
  computed: {
    tableColumns() {
      let filteredColumns = this.columns
      if (this.user.role === 'manager') {
        filteredColumns = this.columns.filter(column => column.field !== 'total_delivery_price' && column.field !== 'repository')
      } else if (this.user.role !== 'admin') {
        filteredColumns = this.columns.filter(column => column.field !== 'total_delivery_price')
      }
      return filteredColumns
    },
  },
  watch: {
    num() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getPaymentFromId()
          this.awaitingSearch = false
        }, 1000)
      }
      this.awaitingSearch = true
    },
  },
  created() {
    this.user = storeAuth.state.user
    this.getDeliveryMans()

    this.getPayments(1)
    if (this.user.role === 'admin') {
      this.getRepositories()
    }
  },
  methods: {
    async getRepositories() {
      await axios.get('/api/repositories/').then(response => {
        this.repositories = response.data
      })
    },
    async getDeliveryMans() {
      this.isLoading = true
      await axios.get('api/auth/users/drivers/').then(response => {
        this.deliveryMans = response.data
        this.isLoading = false
      })
    },
    async getPaymentFromId() {
      if (this.num.length >= 1) {
        this.load = 'true'
        await axios.get(`api/payments/search-by-id/${this.num}`).then(response => {
          this.payments = response.data
          this.load = 'false'
        })
      } else if (this.num.length === 0) {
        this.getPayments(1)
      }
    },
    async pageNext(currentPage) {
      this.getPayments(currentPage)
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    resetFilters() {
      this.deliveryMan = ''
      this.repository = ''
      this.rangePicker = ['', '']
      this.totalDeliveryPrice = 0
      this.isFiltered = false
      this.getPayments(1)
    },
    async exportPayments() {
      try {
        // Show loading indicator
        this.isLoading = true
        this.exportedPayments = null
        let obj = { startDate: '', endDate: '' }
        if (this.rangePicker[0] !== '') {
          const ranges = this.rangePicker.split(' ')
          if (ranges[2] === undefined) {
            obj = { startDate: ranges[0], endDate: ranges[0] }
          } else {
            obj = { startDate: ranges[0], endDate: ranges[2] }
          }
        }
        // Make API request to export data from /payments/export
        const response = await axios.get('api/payments/export/', {
          params: {
            delivery_man: this.deliveryMan.id, repository: this.repository.id, start_date: obj.startDate, end_date: obj.endDate,
          },
        })
        this.exportedPayments = response.data
        this.isLoading = false
      } catch (error) {
        console.error('Error exporting payments:', error)
        // Handle error or display a notification
        this.showToast('danger', 'top-right', 'Failed to export payments')
        this.isLoading = false
      }
    },

    async getPayments(currentPage) {
      this.exportedPayments = null
      this.isLoading = true
      let obj = { startDate: '', endDate: '' }
      if (this.rangePicker[0] !== '') {
        const ranges = this.rangePicker.split(' ')
        if (ranges[2] === undefined) {
          obj = { startDate: ranges[0], endDate: ranges[0] }
        } else {
          obj = { startDate: ranges[0], endDate: ranges[2] }
        }
      }

      const { data } = await axios.get('api/payments/', {
        params: {
          delivery_man: this.deliveryMan.id, repository: this.repository.id, start_date: obj.startDate, end_date: obj.endDate, p: currentPage,
        },
      })
      this.payments = data.results
      this.count = data.count
      this.totalDeliveryPrice = data.total_delivery_price || 0
      this.isLoading = false
      this.isFiltered = !!(this.deliveryMan || this.repository || obj.startDate || obj.endDate)
    },
    async validatePayment(payload) {
      try {
        this.isLoading = true
        const response = await axios.put(`api/payments/validate/${payload}/`)
        if (response.status === 200) {
          this.getPayments(this.currentPage)
        }
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.total-price-shadow {
  text-shadow: 5px 5px 5px rgba(197, 65, 65, 0.3);
  padding: 10px;
  display: inline-block;
}
</style>
